<template>
  <Payments/>
</template>


<script>
export default {
  name: "PaymentComp",
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Payments", path:"", isActive: true}] 
    }
  },
  components: {
    Payments: () => import("../../src/components/payment/Payments"),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>